<template>
	<section id="header" class="hero is-primary">
		<b-navbar centered class="navbar">
			<template #start>
				<b-navbar-item tag="router-link"
					:to="{ name: 'accueil' }"
					:active="$route.name === 'accueil'"
				>
					Accueil
				</b-navbar-item>

				<b-navbar-item tag="router-link"
					:to="{ name: 'mesures2' }"
					:active="$route.name === 'mesures2'"
				>
					De septembre 2020 à avril 2021
				</b-navbar-item>

				<b-navbar-item tag="router-link"
					:to="{ name: 'mesures2sortie' }"
					:active="$route.name === 'mesures2sortie'"
				>
					De mai 2021 à août 2021
				</b-navbar-item>

				<b-navbar-item tag="router-link"
					:to="{ name: 'mesures3' }"
					:active="$route.name === 'mesures3'"
				>
					De décembre 2021 à février 2022
				</b-navbar-item>
			</template>
		</b-navbar>

		<div class="hero-body">
			<div class="columns">
				<div class="column is-narrow title-side">
					<a href="https://www.ghs.fr/" target="_blank">
						<img class="logo" alt="Logo GHS" src="../assets/GHS_blanc.svg"/>
					</a>
				</div>

				<div class="column is-flex is-align-items-center is-justify-content-center">
					<div>
						<p class="title">
							Conditions d'éligibilité aux mesures exceptionnelles COVID-19
							<span v-if="$route.name.startsWith('mesures2')"> - 2ème période</span>
							<span v-if="$route.name === 'mesures3'"> - 3ème période</span>
						</p>

						<!-- Sous-titre Mesures2 -->
						<p class="subtitle" v-if="$route.name === 'mesures2'">
							(LFSS 2021 &amp; décrets des 27/01/2021, 12/04/2021 et 03/06/2021)
						</p>

						<!-- Sous-titre Mesures2Sortie -->
						<p class="subtitle" v-if="$route.name === 'mesures2sortie'">
							Sortie dégressive
						</p>

						<!-- Sous-titre Mesures3 -->
						<p class="subtitle" v-if="$route.name === 'mesures3'">
							(décrets n°2022-170 du 11/02/2022 et n°2022-806 du 13/05/2022)
						</p>
					</div>
				</div>

				<div class="column is-narrow title-side"></div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Header',
}
</script>

<style scoped lang="scss">
@import "../assets/buefy-theme.scss";

#header {
	margin-bottom: 30px;
	text-align: center;

	.navbar {
		color: #efefef;
	}

	.logo {
		width: 150px;
		max-width: none;
	}

	.title-side {
		margin: 0 1rem;
		width: 150px;

		@include mobile {
			margin: 0;
			width: 100%;
		}
	}

	@media print {
		margin-bottom: 0;
		background-color: transparent !important;

		* {
			font-size: 16pt !important;
			color: black;
		}
	}
}

</style>
