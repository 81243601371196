<template>
	<div class="container is-max-desktop">
		<div mobile-mode="minimalist">
			<section class="section content">
				<p>
					Dans le cadre de la crise sanitaire, le Gouvernement a mis en œuvre un ensemble de mesures exceptionnelles qui ont pour objectif d'accompagner la trésorerie des entreprises et associations.
				</p>
				<p>
					Les mesures concernent les différentes périodes de restrictions d'activité, pour les périodes d'emploi de février 2020 à février 2022 :
				</p>

				<!-- === Période 1 (février - mai 2020) === -->
				<div class="card my-5">
					<header class="card-header">
						<p class="card-header-title">
							Mesures liées à la première vague de la crise sanitaire au printemps 2020 pour la période de février à mai 2020
						</p>
					</header>

					<div class="card-content">
						<div class="content">
							<p class="has-text-centered">
								<a href="https://www.mesures-covid19.urssaf.fr/periode-01-02-2020-30-08-2020/" target="_blank">
									<b-button tag="a" type="is-primary is-light" size="is-medium" class="wrap">
										Vérifiez votre éligibilité de février 2020 à mai 2020
									</b-button>
								</a>
							</p>
						</div>
					</div>
				</div>

				<!-- === Période 2 (septembre 2020 - avril 2021) === -->
				<div class="card my-5">
					<header class="card-header">
						<p class="card-header-title">
							Mesures décidées à l'automne 2020
						</p>
					</header>

					<div class="card-content">
						<div class="content">
							<p class="has-text-centered">
								<b-button tag="router-link" :to="{ name: 'mesures2' }"
									type="is-primary is-light" size="is-medium"
									class="wrap"
								>
									Vérifiez votre éligibilité de septembre 2020 à avril 2021
								</b-button>
							</p>
						</div>
					</div>
				</div>

				<!-- === Période 2 bis (mai - août 2021) === -->
				<div class="card my-5">
					<header class="card-header">
						<p class="card-header-title">
							Mesures décidées au printemps 2021
						</p>
					</header>

					<div class="card-content">
						<div class="content">
							<p>
								Afin d'accompagner la reprise d'activité des secteurs les plus impactés par les conséquences économiques et financières de la crise sanitaire liée au Covid-19 et permettre ainsi <strong>une sortie dégressive</strong> des dispositifs précédents, une nouvelle aide au paiement des cotisations Urssaf et Chômage est mise en place par la loi de finance rectificative pour 2021.
							</p>
							<p>
								Pour savoir si vous ouvrez droit à des exonérations ou aide au paiement pour les périodes d'emploi à compter de mai 2021, répondez aux questions suivantes :
							</p>
							<p class="has-text-centered">
								<b-button tag="router-link"
									:to="{ name: 'mesures2sortie' }"
									type="is-primary is-light" size="is-medium"
									class="wrap"
								>
									Vérifiez votre éligibilité de mai 2021 à août 2021
								</b-button>
							</p>
						</div>
					</div>
				</div>

				<!-- === Période 3 (décembre 2021 - février 2022) === -->
				<div class="card my-5">
					<header class="card-header">
						<p class="card-header-title">
							Mesures prévues par la loi de janvier 2022
						</p>
					</header>

					<div class="card-content">
						<div class="content">
							<p class="has-text-centered">
								<b-button tag="router-link"
									:to="{ name: 'mesures3' }"
									type="is-primary is-light" size="is-medium"
									class="wrap"
								>
									Vérifiez votre éligibilité de décembre 2021 à février 2022
								</b-button>
							</p>
						</div>
					</div>
				</div>

			</section>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Accueil',
}
</script>

<style scoped>
.wrap {
	white-space: normal;
	height: max-content;
}
</style>
