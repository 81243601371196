<template>
	<div class="box">
		<section class="section is-medium">
			<h3 class="subtitle is-4 has-text-centered">
				Découvrez si vous êtes éligible en 6 questions simples
			</h3>
		</section>

		<section class="section">
			<div class="message">
				<p class="message-body">
					Attention, sont exclus du dispositif les employeurs non éligibles à la réduction générale, c'est-à-dire l'État, les collectivités territoriales, les établissements publics administratifs, à l'exception de ceux inscrits au répertoire des entreprises contrôlées majoritairement par l'État, ainsi que les groupements d'intérêt public et les chambres consulaires.
				</p>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'Presentation',
}
</script>

<style scoped>

</style>
