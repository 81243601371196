<template>
	<Question :demo="demo">
		<template v-slot:question>
			À quel secteur d'activité appartenez-vous et quel est votre effectif ?
		</template>

		<template v-slot:aide>
			<p>
				Si vous ne savez pas, vous pouvez consulter <a href="https://ghs.fr/IMG/pdf/COVID2-ListeSecteurs.pdf" target="_blank">la liste des secteurs</a>.
			</p>
			<p class="mt-3">
				L'effectif pris en compte peut être celui calculé au 31 décembre 2019, au 31 décembre 2020 ou, pour les entreprises créées en 2020, au dernier jour du mois au cours duquel a été réalisée la première embauche.
			</p>
		</template>

		<template v-slot:input>
			<b-field v-for="choi in choix" :key="choi.value">
				<b-radio
					v-model="secteurEffectif"
					:native-value="choi.value"
					:disabled="demo"
				>
					<span v-html="choi.label"></span>
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},

	name: 'QSecteurEffectif-m2s',

	props: {
		demo: Boolean,
	},

	data: () => ({
		choix: [
			{ value: "S1_ou_inf250", label: "Secteur S1 ou S1bis ; et mon effectif est inférieur à 250 salariés" },
			{ value: "NonS1_ou_sup250", label: "Je n'appartiens ni au secteur S1, ni au S1bis ; ou mon effectif est supérieur ou égal à 250 salariés" },
		],
	}),

	computed: {
		secteurEffectif: {
			get () {
				return this.$store.state.m2s.secteurEffectif
			},
			set (secteurEffectif) {
				this.$store.commit('m2s/setSecteurEffectif', secteurEffectif)
			}
		},
	},
}
</script>

<style scoped>

</style>
