import Vue from 'vue'
import VueRouter from 'vue-router'
import Accueil from '@/views/Accueil'
import MesuresCovid2 from '@/views/MesuresCovid2'
import MesuresCovid2Sortie from '@/views/MesuresCovid2Sortie'
import MesuresCovid3 from '@/views/MesuresCovid3'

Vue.use(VueRouter)

const routes = [
	{
		path: '/mesures-covid19-2e-periode-non-degressif',
		name: 'mesures2',
		component: MesuresCovid2
	},
	{
		path: '/mesures-covid19-2e-periode',
		name: 'mesures2sortie',
		component: MesuresCovid2Sortie
	},
	{
		path: '/mesures-covid19-3e-periode',
		name: 'mesures3',
		component: MesuresCovid3
	},
	{
		path: '/',
		name: 'accueil',
		component: Accueil
	},
	{
		path: '*',
		redirect: '/'
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
