<template>
	<div class="container">
		<b-steps
			id="stepper"
			v-model="activeStep"
			:animated="true"
			:rounded="true"
			:has-navigation="true"
			mobile-mode="minimalist"
		>
			<!-- 0 -->
			<b-step-item step="i" label="Présentation">
				<Presentation />
			</b-step-item>

			<!-- 1 -->
			<b-step-item step="1" label="Interdiction d'accueil du public">
				<QInterdictionAccueil />
			</b-step-item>

			<!-- 2 -->
			<b-step-item step="2" label="Secteur et effectif">
				<QSecteurEffectif />
			</b-step-item>

			<!-- 3 -->
			<b-step-item step="3" label="Jauge" :visible="concerneParJauge">
				<QJauge />
			</b-step-item>

			<!-- 4 -->
			<b-step-item step="4" label="Aides" :visible="concerneParAidesFMA21">
				<QAidesFMA21 />
			</b-step-item>

			<!-- 5 -->
			<b-step-item step="✓" label="Éligibilité">
				<Eligibilite />
			</b-step-item>

			<!-- Précédent / Suivant / Terminer -->
			<template #navigation="{ previous, next }">
				<div id="nav-stepper" class="container has-text-centered">
					<b-button
						class="mx-1" type="is-link is-light"
						:disabled="previous.disabled"
						@click.prevent="previous.action"
					>
						Précédent
					</b-button>
					<b-button
						class="mx-1" type="is-link"
						v-if="!showTermine && activeStep !== LAST_STEP"
						:disabled="next.disabled || mostAdvancedFilledStep < activeStep"
						@click.prevent="next.action"
					>
						Suivant
					</b-button>
					<b-button
						class="mx-1" type="is-success"
						v-if="showTermine && activeStep !== LAST_STEP"
						@click.prevent="terminer"
					>
						Terminer
					</b-button>
				</div>
			</template>
		</b-steps>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Presentation from '@/components/MesuresCovid2Sortie/0_Presentation.vue'
import QInterdictionAccueil from '@/components/MesuresCovid2Sortie/1_InterdictionAccueil.vue'
import QSecteurEffectif from '@/components/MesuresCovid2Sortie/2_SecteurEffectif.vue'
import QJauge from '@/components/MesuresCovid2Sortie/3_Jauge.vue'
import QAidesFMA21 from '@/components/MesuresCovid2Sortie/4_AidesFMA21.vue'
import Eligibilite from '@/components/MesuresCovid2Sortie/5_Eligibilite.vue'

export default {
	components: {
		Presentation,
		QInterdictionAccueil,
		QSecteurEffectif,
		QJauge,
		QAidesFMA21,
		Eligibilite,
	},

	name: 'MesuresCovid2Sortie',

	data: () => ({
		LAST_STEP: 5,

		/** Indice de la question affichée */
		activeStep: 0,
	}),

	computed: {
		/**
		 * Indice de la dernière question répondue.
		 */
		mostAdvancedFilledStep() {
			if (this.$store.state.m2s.aidesFMA21) { return 4 }
			if (this.$store.state.m2s.jauge) { return 3 }
			if (this.$store.state.m2s.secteurEffectif) { return 2 }
			if (this.$store.state.m2s.interdictionAccueil) { return 1 }
			return 0
		},

		/**
		 * S'il faut afficher le bouton "Terminer" au lieu de "Suivant".
		 */
		showTermine() {

			let lastStep = 2;
			if (this.concerneParJauge) { lastStep = 3; }
			if (this.concerneParAidesFMA21) { lastStep = 4; }

			return (
				// On est au dernier step répondu (pour pouvoir faire "Suivant" si on est retourné en arrière) :
				this.mostAdvancedFilledStep === this.activeStep &&

				// On vient de répondre à la dernière question :
				this.mostAdvancedFilledStep === lastStep
			)
		},

		...mapGetters('m2s', [
			'concerneParJauge',
			'concerneParAidesFMA21',
		])
	},

	methods: {
		terminer() {
			this.activeStep = this.LAST_STEP

			if (!this.$store.state.m2s.reponseEnvoyee) {
				this.$store.dispatch("m2s/postReponse")
			}
		}
	}
}
</script>

<style>
@media print {
	#stepper > nav.steps {
		display: none;
	}

	#nav-stepper {
		display: none;
	}
}
</style>
