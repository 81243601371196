<template>
	<Question :demo="demo">
		<template v-slot:question>
			Quel est l'effectif de votre entreprise ?
		</template>

		<template v-slot:aide>
			L'effectif pris en compte peut être celui calculé au 31 décembre 2019, au 31 décembre 2020 ou, pour les entreprises créées en 2020 ou 2021, au dernier jour du mois au cours duquel a été réalisée la première embauche.
		</template>

		<template v-slot:input>
			<b-field>
				<b-radio
					v-model="effectif"
					native-value="inf250"
					:disabled="demo"
				>
					Inférieur à 250 salariés
				</b-radio>
			</b-field>
			<b-field>
				<b-radio
					v-model="effectif"
					native-value="sup250"
					:disabled="demo"
				>
					Supérieur ou égal à 250 salariés
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'

export default {
	components: {
		Question,
	},

	name: 'QEffectif',

	props: {
		demo: Boolean,
	},

	computed: {
		effectif: {
			get () {
				return this.$store.state.m3.effectif
			},
			set (effectif) {
				this.$store.commit('m3/setEffectif', effectif)
			}
		},
	},
}
</script>

<style scoped>

</style>
