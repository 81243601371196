<template>
	<div class="box">
		<!-- QUESTION -->
		<section id="question" :class="classes.sectionQuestion">
			<h3 :class="classes.question">
				<slot name="question"></slot>
			</h3>
		</section>

		<!-- AIDE -->
		<div id="aide" class="message" v-if="!demo && hasAide">
			<p class="message-body">
				<slot name="aide"></slot>
			</p>
		</div>

		<!-- INPUT -->
		<div id="input" :class="classes.sectionInputs">
			<slot name="input"></slot>
		</div>
	</div>
</template>

<script>
import { classes } from '@/store'

/**
 * Template des Questions.
 * @slot `question` : Énoncé de la question
 * @slot `aide` : (optionnel) Message d'aide
 * @slot `input` : Moyen de répondre à la question
 */
export default {
	name: 'Question',

	props: {
		demo: Boolean,
	},

	computed: {
		hasAide() {
			return !!this.$slots.aide
		},

		classes() {
			if (this.demo) {
				return classes.demo
			}
			return classes.normal
		},
	}
}
</script>

<style lang="scss">

/* Petits écrans (largeur ou hauteur insuffisantes pour lire la plus longue question) => diminution des marges des sections */
@media (max-height: 700px), (max-width: 600px) {
	#question, #input {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@media print {
	#question, #input {
		padding-top: 0;
		padding-bottom: 0;
	}

	.field {
		margin-bottom: 0 !important;
	}
}

</style>
