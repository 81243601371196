<template>
	<Question :demo="demo">
		<template v-slot:question>
			Êtes-vous resté soumis à des mesures de jauges inférieures à 50&nbsp;% de l'effectif autorisé en début de mois de juin 2021 ?
		</template>

		<template v-slot:input>
			<b-field v-for="choi in choix" :key="choi.value">
				<b-radio
					v-model="jauge"
					:native-value="choi.value"
					:disabled="demo"
				>
					<span v-html="choi.label"></span>
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},

	name: 'QJauge-m2s',

	props: {
		demo: Boolean,
	},

	data: () => ({
		choix: [
			{ value: "oui", label: "Oui, je suis resté soumis à des mesures de jauges inférieures à 50&nbsp;% jusqu'au 08/06/2021" },
			{ value: "non", label: "Non" },
		],
	}),

	computed: {
		jauge: {
			get () {
				return this.$store.state.m2s.jauge
			},
			set (jauge) {
				this.$store.commit('m2s/setJauge', jauge)
			}
		},
	},
}
</script>

<style scoped>

</style>
