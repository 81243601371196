import { postReponse } from '../api'

export const Mois = {
	OCTOBRE: 'oct',
	NOVEMBRE: 'nov',
	DECEMBRE: 'dec',
	JANVIER: 'jan',
	FEVRIER: 'fev',
	MARS: 'mar',
	AVRIL: 'avr',
	MAI: 'mai',
	JUIN: 'juin',
}
/** Assuré d'être dans l'ordre */
export const MoisListe = [
	'oct',
	'nov',
	'dec',
	'jan',
	'fev',
	'mar',
	'avr',
	'mai',
	'juin',
]

export const mesures2store = {

	namespaced: true,
	strict: process.env.NODE_ENV !== 'production',

	// =======================================================
	// == STATE
	// =======================================================

	state: {
		// === Questions ===
		secteur: undefined,
		effectif: undefined,
		couvreFeuOctobre: undefined,
		interdictionAccueil: undefined,
		baisseCA: undefined,

		// // Pour debug rapide :
		// secteur: "S1",
		// effectif: "inferieur",
		// couvreFeuOctobre: "oui",
		// interdictionAccueil: ['oct', 'nov'],
		// baisseCA: ['dec'],

		// === Stats ===
		formId: "mesures-covid-2-v7",
		reponseEnvoyee: false,
	},

	// =======================================================
	// == GETTERS
	// =======================================================

	getters: {
		reponses(state) {
			return {
				secteur: state.secteur,
				effectif: state.effectif,
				couvreFeuOctobre: state.couvreFeuOctobre,
				interdictionAccueil: state.interdictionAccueil,
				baisseCA: state.baisseCA,
			}
		},

		moisValides(state, getters) {
			const moisState = getters.mois
			const moisValides = []

			for (const mois of MoisListe) {
				if (moisState[mois].valide) {
					moisValides.push(moisState[mois])
				}
			}

			return moisValides
		},

		/**
		 * Retourne un dictionnaire des mois au format :
		 * ```
		 * {
		 * 	label: "Libellé du mois à afficher dans les questions",
		 * 	moiseligible: "Libellé du mois éligible si celui-ci est validé",
		 * 	valide: "`true` si le mois a été validé",
		 * 	exclu: "`true` si le mois a été spécifiquement écarté avant la fin du formulaire",
		 * 	interdictionAccueil: "`true` s'il faut proposer ce choix à la question Interdiction d'accueil du public"
		 * 	baisseCA: "`true` s'il faut le proposer à la question Baisse du chiffre d'affaires"
		 * }
		 * ```
		 */
		mois(state) {
			return {
				[Mois.OCTOBRE]: {
					label: "Octobre 2020",
					moisEligible: "Septembre 2020",
					valide: (
						state.interdictionAccueil?.includes(Mois.OCTOBRE) ||
						state.baisseCA?.includes(Mois.OCTOBRE)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.secteur === "S2" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: (state.couvreFeuOctobre === "oui"),
					baisseCA: (
						// Couvre-feu (si S1) ou S1bis
						(
							state.couvreFeuOctobre === "oui" ||
							state.secteur === "S1bis"
						)
						&&
						// Pas déjà coché à "interdiction d'accueil"
						!state.interdictionAccueil?.includes(Mois.OCTOBRE)
					)
				},

				[Mois.NOVEMBRE]: {
					label: "Novembre 2020",
					moisEligible: "Octobre 2020",
					valide: (
						state.interdictionAccueil?.includes(Mois.NOVEMBRE) ||
						state.baisseCA?.includes(Mois.NOVEMBRE)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.NOVEMBRE)
					)
				},

				[Mois.DECEMBRE]: {
					label: "Décembre 2020",
					moisEligible: "Novembre 2020",
					valide: (
						state.interdictionAccueil?.includes(Mois.DECEMBRE) ||
						state.baisseCA?.includes(Mois.DECEMBRE)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.DECEMBRE)
					)
				},

				[Mois.JANVIER]: {
					label: "Janvier 2021",
					moisEligible: "Décembre 2020",
					valide: (
						state.interdictionAccueil?.includes(Mois.JANVIER) ||
						state.baisseCA?.includes(Mois.JANVIER)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.JANVIER)
					)
				},

				[Mois.FEVRIER]: {
					label: "Février 2021",
					moisEligible: "Janvier 2021",
					valide: (
						state.interdictionAccueil?.includes(Mois.FEVRIER) ||
						state.baisseCA?.includes(Mois.FEVRIER)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.FEVRIER)
					)
				},

				[Mois.MARS]: {
					label: "Mars 2021",
					moisEligible: "Février 2021",
					valide: (
						state.interdictionAccueil?.includes(Mois.MARS) ||
						state.baisseCA?.includes(Mois.MARS)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.MARS)
					)
				},

				[Mois.AVRIL]: {
					label: "Avril 2021",
					moisEligible: "Mars 2021",
					valide: (
						state.interdictionAccueil?.includes(Mois.AVRIL) ||
						state.baisseCA?.includes(Mois.AVRIL)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.AVRIL)
					)
				},

				[Mois.MAI]: {
					label: "Mai 2021",
					moisEligible: "Avril 2021",
					valide: (
						state.interdictionAccueil?.includes(Mois.MAI) ||
						state.baisseCA?.includes(Mois.MAI)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: (
						state.secteur !== "S2" &&
						!state.interdictionAccueil?.includes(Mois.MAI)
					)
				},

				[Mois.JUIN]: {
					label: "Juin 2021",
					moisEligible: "Mai 2021",
					valide: (
						state.interdictionAccueil?.includes(Mois.JUIN)
					),
					exclu: (
						state.secteur === "aucun" ||
						state.effectif === "superieur"
					),
					interdictionAccueil: true,
					baisseCA: false
				},
			}
		},

		concerneParEffectif(state) {
			return ["S1", "S1bis", "S2"].indexOf(state.secteur) > -1
		},

		concerneParCouvreFeuOctobre(state) {
			return (
				["S1", "S1bis"].indexOf(state.secteur) > -1 &&
				state.effectif === "inferieur"
			)
		},

		concerneParInterdictionAccueil(state) {
			return (
				(
					["S1", "S1bis"].indexOf(state.secteur) > -1 &&
					!!state.couvreFeuOctobre
				) ||
				(
					state.secteur === "S2" &&
					state.effectif === "inferieur"
				)
			)
		},

		concerneParBaisseCA(state, getters) {

			// S1(bis) only
			if (["S1", "S1bis"].indexOf(state.secteur) < 0) {
				return false;
			}

			const stateMois = getters.mois;

			// Vérifier qu'il reste des mois (non exclus ou validés) pour la question Baisse chiffre d'affaires
			const resteMoisPourBaisseCA = Object.values(stateMois)
				.filter(mois => (
					mois.baisseCA &&
					!mois.exclu &&
					!mois.valide
				)).length > 0;

			return (
				// Avoir répondu à la question sur l'Interdiction d'accueil
				!!state.interdictionAccueil &&
				// Avoir des mois potentiels restants
				resteMoisPourBaisseCA
			)
		},

		/**
		 * Vérifie si tous les mois ont été soit validés soit exclus.
		 */
		tousMoisTraites(state, getters) {
			const stateMois = getters.mois
			for (const mois in stateMois) {
				if (!stateMois[mois].valide && !stateMois[mois].exclu) {
					return false
				}
			}
			return true
		},
	},

	// =======================================================
	// == MUTATIONS
	// =======================================================

	mutations: {
		/**
		 * @param {string} secteur `S1`, `S1bis`, `S2` ou `aucun`
		 */
		setSecteur(state, secteur) {
			// Secteur
			state.secteur = secteur

			// Reset suivants
			state.effectif = undefined
			state.couvreFeuOctobre = undefined
			state.interdictionAccueil = undefined
			state.baisseCA = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},
		/**
		 * @param {boolean} inferieur `true` si effectif inférieur au seuil, `false` s'il est supérieur ou égal
		 */
		setEffectif(state, inferieur) {
			// Effectif
			state.effectif = inferieur

			// Reset suivants
			state.couvreFeuOctobre = undefined
			state.interdictionAccueil = undefined
			state.baisseCA = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},
		/**
		 * @param {boolean} affecte `true` si l'entreprise a été affectée par le couvre-feu d'octobre
		 */
		setCouvreFeuOctobre(state, affecte) {
			// Couvre-feu
			state.couvreFeuOctobre = affecte

			// Reset suivants
			state.interdictionAccueil = undefined
			state.baisseCA = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},
		/**
		 * @param {string[]} mois
		 */
		setInterdictionAccueil(state, mois) {
			// Interdiction d'accueil
			state.interdictionAccueil = mois

			// Reset suivant
			state.baisseCA = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},
		/**
		 * @param {string[]} mois
		 */
		setBaisseCA(state, mois) {
			// Baisse CA
			state.baisseCA = mois

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setReponseEnvoyee(state) {
			state.reponseEnvoyee = true
		},
	},

	// =======================================================
	// == ACTIONS
	// =======================================================

	actions: {

		async postReponse(context) {

			const formId = context.state.formId
			const reponses = context.getters.reponses
			const moisValides = context.getters.moisValides
			const moisEligibles = moisValides.map(mois => mois.moisEligible)

			const resp = await postReponse(formId, {
				reponses,
				moisEligibles,
			})
			console.log("Réponse envoyée", resp)

			context.commit('m2/setReponseEnvoyee')
		},

	},
}
