<template>
	<Question :demo="demo">
		<template v-slot:question>
			Avez-vous subi une interdiction d'accueil du public ?
		</template>

		<template v-slot:aide>
			<p>
				Satisfont le critère d'interdiction d'accueil du public les activités qui ont été interrompues en application du <a href="https://www.service-public.fr/professionnels-entreprises/vosdroits/N31782" target="_blank">décret n°2020-1310 du 29/10/2020</a> ou d'une décision locale.
			</p>
			<p>
				En revanche, les cas où l'activité a été interrompue de manière volontaire par l'employeur n'ouvrent pas droit aux dispositifs.
			</p>
		</template>

		<template v-slot:input>
			<b-field v-for="choi in choix" :key="choi.value">
				<b-radio
					v-model="interdictionAccueil"
					:native-value="choi.value"
					:disabled="demo"
				>
					<span v-html="choi.label"></span>
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},

	name: 'QInterdictionAccueil-m2s',

	props: {
		demo: Boolean,
	},

	data: () => ({
		choix: [
			{ value: "juin", label: "Uniquement en juin 2021" },
			{ value: "juinJuillet", label: "En juin et en juillet 2021" },
			{ value: "non", label: "Je n'ai subi aucune interdiction d'accueil du public depuis juin 2021" },
		],
	}),

	computed: {
		interdictionAccueil: {
			get () {
				return this.$store.state.m2s.interdictionAccueil
			},
			set (interdictionAccueil) {
				this.$store.commit('m2s/setInterdictionAccueil', interdictionAccueil)
			}
		},
	},
}
</script>

<style scoped>

</style>
