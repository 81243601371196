<template>
	<div class="box">

		<!-- === Mois complets === -->
		<template v-if="eligibilite.complets.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Mesures complètes" type="is-success" :closable="false">
					Vous continuez à ouvrir droit aux mesures complètes en
					<ul>
						<li v-for="mois in eligibilite.complets" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>

					<!-- Explications -->
					<b-message class="mt-3">
						Les mesures complètes comprennent : l'exonération de cotisations patronales et une aide au paiement de 20&nbsp;%.
					</b-message>
				</b-message>
			</div>

		</template>

		<!-- === Mois dégressifs === -->
		<template v-if="eligibilite.degressifs.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Sortie dégressive" type="is-success" :closable="false">
					Vous bénéficiez du dispositif de sortie dégressive pour
					<ul>
						<li v-for="mois in eligibilite.degressifs" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>

					<!-- Explications -->
					<b-message class="mt-3">
						Le dispositif de sortie dégressive comprend une aide au paiement de 15&nbsp;% sur les cotisations Acoss, mais pas d'exonération des cotisations patronales.
					</b-message>
				</b-message>
			</div>

		</template>

		<!-- === Mois sans aide === -->
		<template v-if="eligibilite.rien.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Fin des aides" type="is-warning" :closable="false">
					Vous ne bénéficiez plus d'aucune mesure d'exonération de cotisations patronales ou aide au paiement pour
					<ul>
						<li v-for="mois in eligibilite.rien" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>
				</b-message>
			</div>
		</template>

		<!-- === RÉCAPITULATIF === -->
		<section id="recap" class="m-3 pt-5">
			<h4 class="title">Récapitulatif de vos réponses</h4>
			<QInterdictionAccueil demo />
			<QSecteurEffectif demo />
			<QJauge demo v-if="concerneParJauge" />
			<QAidesFMA21 demo v-if="concerneParAidesFMA21" />
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import QInterdictionAccueil from '@/components/MesuresCovid2Sortie/1_InterdictionAccueil.vue';
import QSecteurEffectif from '@/components/MesuresCovid2Sortie/2_SecteurEffectif.vue';
import QJauge from '@/components/MesuresCovid2Sortie/3_Jauge.vue';
import QAidesFMA21 from '@/components/MesuresCovid2Sortie/4_AidesFMA21.vue';

export default {
	components: {
		QInterdictionAccueil,
		QSecteurEffectif,
		QJauge,
		QAidesFMA21,
	},

	name: 'Eligibilite-m2s',

	computed: {
		...mapGetters('m2s', [
			'concerneParJauge',
			'concerneParAidesFMA21',
			'eligibilite',
		])
	},
}
</script>

<style scoped lang="scss">
@media print {
	.eligibilite {
		background-color: transparent !important;

		* {
			color: black !important;
			font-size: 15pt !important;
		}

		.hero-body {
			padding-top: 3pt;
			padding-bottom: 3pt;
		}
	}

	.eligibilite-subtitle {
		padding-top: 3pt;
		padding-bottom: 3pt;

		* {
			font-size: 13pt !important;
		}
	}

	.eligibilite-message p {
		font-size: 11pt !important;
	}

	#recap h4 {
		font-size: 13pt;
	}
}
</style>
