<template>
	<div class="container">
		<b-steps
			id="stepper"
			v-model="activeStep"
			:animated="true"
			:rounded="true"
			:has-navigation="true"
			mobile-mode="minimalist"
		>
			<!-- 0 -->
			<b-step-item step="i" label="Présentation">
				<Presentation />
			</b-step-item>

			<!-- 1 -->
			<b-step-item label="Secteur"
				:step="affichageSteps.secteur"
			>
				<QSecteur />
			</b-step-item>

			<!-- 2 -->
			<b-step-item label="Effectif"
				:step="affichageSteps.effectif"
				:visible="concerneParEffectif"
			>
				<QEffectif />
			</b-step-item>

			<!-- 3 -->
			<b-step-item label="Couvre-feu"
				:step="affichageSteps.couvreFeuOctobre"
				:visible="concerneParCouvreFeuOctobre"
			>
				<QCouvreFeu />
			</b-step-item>

			<!-- 4 -->
			<b-step-item label="Accueil public"
				:step="affichageSteps.interdictionAccueil"
				:visible="concerneParInterdictionAccueil"
			>
				<QInterdictionAccueilPublic />
			</b-step-item>

			<!-- 5 -->
			<b-step-item label="Chiffre d'affaires"
				:step="affichageSteps.baisseCA"
				:visible="concerneParBaisseCA"
			>
				<QBaisseCA />
			</b-step-item>

			<!-- 6 -->
			<b-step-item step="✓" label="Éligibilité">
				<Eligibilite />
			</b-step-item>

			<!-- Précédent / Suivant / Terminer -->
			<template #navigation="{ previous, next }">
				<div id="nav-stepper" class="container has-text-centered">
					<b-button
						class="mx-1" type="is-link is-light"
						:disabled="previous.disabled"
						@click.prevent="previous.action"
					>
						Précédent
					</b-button>
					<b-button
						class="mx-1" type="is-link"
						v-if="!showTermine && activeStep !== LAST_STEP"
						:disabled="next.disabled || mostAdvancedFilledStep < activeStep"
						@click.prevent="next.action"
					>
						Suivant
					</b-button>
					<b-button
						class="mx-1" type="is-success"
						v-if="showTermine && activeStep !== LAST_STEP"
						@click.prevent="terminer"
					>
						Terminer
					</b-button>
				</div>
			</template>
		</b-steps>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Presentation from '@/components/MesuresCovid2/0_Presentation.vue'
import QSecteur from '@/components/MesuresCovid2/1_Secteur.vue'
import QEffectif from '@/components/MesuresCovid2/2_Effectif.vue'
import QCouvreFeu from '@/components/MesuresCovid2/3_CouvreFeu.vue'
import QInterdictionAccueilPublic from '@/components/MesuresCovid2/4_InterdictionAccueilPublic.vue'
import QBaisseCA from '@/components/MesuresCovid2/5_BaisseCA.vue'
import Eligibilite from '@/components/MesuresCovid2/6_Eligibilite.vue'
export default {
	components: {
		Presentation,
		QSecteur,
		QEffectif,
		QCouvreFeu,
		QInterdictionAccueilPublic,
		QBaisseCA,
		Eligibilite,
	},

	name: 'MesuresCovid',

	data: () => ({
		LAST_STEP: 6,

		/** Indice de la question affichée */
		activeStep: 0,

		// // Pour debug rapide :
		// activeStep: 5,
	}),

	computed: {
		/**
		 * Indice de la dernière question répondue.
		 */
		mostAdvancedFilledStep() {
			if (this.$store.state.m2.baisseCA) { return 5 }
			if (this.$store.state.m2.interdictionAccueil) { return 4 }
			if (this.$store.state.m2.couvreFeuOctobre) { return 3 }
			if (this.$store.state.m2.effectif) { return 2 }
			if (this.$store.state.m2.secteur) { return 1 }
			return 0
		},

		/**
		 * S'il faut afficher le bouton "Terminer" au lieu de "Suivant".
		 */
		showTermine() {

			// Cas particulier : pas de step 5 (baisseCA) pour le S2
			const lastStep = this.$store.state.m2.secteur === 'S2' ? 4 : this.LAST_STEP - 1

			return (
				// On est au dernier step répondu (pour pouvoir faire "Suivant" si on est retourné en arrière) :
				this.mostAdvancedFilledStep === this.activeStep &&
				(
					// Tous les mois ont été validés ou exclus :
					this.$store.getters['m2/tousMoisTraites'] ||

					// On vient de répondre à la dernière question :
					this.mostAdvancedFilledStep === lastStep
				)
			)
		},

		/**
		 * Adapte le numéro d'affichage de chaque question en fonction de celles visibles.
		 */
		affichageSteps() {
			const steps = {
				secteur: '1',
			};

			var nextStep = 2;

			if (this.concerneParEffectif) { steps.effectif = (nextStep++).toString() }
			if (this.concerneParCouvreFeuOctobre) { steps.couvreFeuOctobre = (nextStep++).toString() }
			if (this.concerneParInterdictionAccueil) { steps.interdictionAccueil = (nextStep++).toString() }
			if (this.concerneParBaisseCA) { steps.baisseCA = (nextStep++).toString() }

			return steps;
		},

		...mapGetters('m2', [
			'concerneParEffectif',
			'concerneParCouvreFeuOctobre',
			'concerneParInterdictionAccueil',
			'concerneParBaisseCA',
		])
	},

	methods: {
		terminer() {
			this.activeStep = this.LAST_STEP

			if (!this.$store.state.m2.reponseEnvoyee) {
				this.$store.dispatch("m2/postReponse")
			}
		}
	}
}
</script>

<style>
@media print {
	#stepper > nav.steps {
		display: none;
	}

	#nav-stepper {
		display: none;
	}
}
</style>
