import { postReponse } from '../api'

/**
 * Store du formulaire sur la sortie dégressive.
 * @see https://www.notion.so/ghs75009/Mesures-COVID-912741ac7b3c459eb2d4d2bd9730f443#b39594490a6a49b9b49b3eeece222121
 */
export const mesures3Store = {

	namespaced: true,
	strict: process.env.NODE_ENV !== 'production',

	// =======================================================
	// == STATE
	// =======================================================

	state: {
		// === Questions ===
		/**
		 * @type {'S1_ou_bis' | 'aucun'}
		 */
		secteur: undefined,
		/**
		 * @type {'inf250' | 'sup250'}
		 */
		effectif: undefined,
		/**
		 * @type {'oui' | 'non'}
		 */
		interdictionAccueil: undefined,
		/**
		 * @type {'sup65' | '30_65' | 'inf30'}
		 */
		baisseCADecembre: undefined,
		/**
		 * @type {'sup65' | '30_65' | 'inf30'}
		 */
		baisseCAJanvier: undefined,
		/**
		 * @type {'sup65' | '30_65' | 'inf30'}
		 */
		baisseCAFevrier: undefined,

		// === Stats ===
		formId: "mesures-covid-3-v2",
		reponseEnvoyee: false,
	},

	// =======================================================
	// == GETTERS
	// =======================================================

	getters: {

		/**
		 * Les réponses données, pour être envoyées à l'API pour les stats.
		 */
		reponses: (state) => {
			return {
				secteur: state.secteur,
				effectif: state.effectif,
				interdictionAccueil: state.interdictionAccueil,
				baisseCADecembre: state.baisseCADecembre,
				baisseCAJanvier: state.baisseCAJanvier,
				baisseCAFevrier: state.baisseCAFevrier,
			}
		},

		/**
		 * Calcule les mois éligibles à l'aide au paiement et à l'exonération en fonction des réponses données.
		 */
		eligibilite: (state) => {
			const mois = {
				aidePlusExo: [],
				aide20: [],
				aide15: [],
				rien: [],
			}

			// Rien si...
			if (
				// 1. Pas secteur S1(bis) :
				state.secteur === "aucun" ||
				// 2. OU effectif > 250 :
				state.effectif === "sup250" ||
				// 3. OU pas d'interdiction d'accueil ET baisseCA décembre < 30% ET baisseCA janvier < 30% :
				(
					state.interdictionAccueil === "non" &&
					state.baisseCADecembre === "inf30" &&
					state.baisseCAJanvier === "inf30" &&
					state.baisseCAFevrier === "inf30"
				)
			) {
				mois.rien = ["Décembre 2021", "Janvier 2022", "Février 2022"]
				return mois
			}

			// Novembre 2021 :
			// - aide + exo si interdiction d'accueil
			// - rien sinon (mais ne pas l'afficher car cas particulier)
			if (state.interdictionAccueil === "oui") {
				mois.aidePlusExo.push("Novembre 2021")
			}

			// Décembre 2021 :
			// - aide + exo si interdiction OU baisseCA décembre > 65%
			// - aide 20% si baisseCA décembre entre 30 et 65%
			// - rien sinon
			if (
				state.interdictionAccueil === "oui" ||
				state.baisseCADecembre === "sup65"
			) {
				mois.aidePlusExo.push("Décembre 2021")
			}
			else if (state.baisseCADecembre === "30_65") {
				mois.aide20.push("Décembre 2021")
			}
			else {
				mois.rien.push("Décembre 2021")
			}

			// Janvier 2022 :
			// - aide + exo si interdiction OU baisse CA janvier > 65%
			// - aide 20% si baisseCA janvier entre 30 et 65%
			// - rien sinon
			if (
				state.interdictionAccueil === "oui" ||
				state.baisseCAJanvier === "sup65"
			) {
				mois.aidePlusExo.push("Janvier 2022")
			}
			else if (state.baisseCAJanvier === "30_65") {
				mois.aide20.push("Janvier 2022")
			}
			else {
				mois.rien.push("Janvier 2022")
			}

			// Février 2022 :
			// - aide + exo si interdiction d'accueil OU baisse CA février > 65%
			// - aide 15% si baisseCA février entre 30 et 65%
			// - rien sinon
			if (
				state.interdictionAccueil === "oui" ||
				state.baisseCAFevrier === "sup65"
			) {
				mois.aidePlusExo.push("Février 2022")
			}
			else if (state.baisseCAFevrier === "30_65") {
				mois.aide15.push("Février 2022")
			}
			else {
				mois.rien.push("Février 2022")
			}

			return mois
		},

		/**
		 * Détermine si on doit afficher la question 2 sur l'effectif.
		 */
		concerneParEffectif: (state) => {
			return state.secteur === "S1_ou_bis"
		},

		/**
		 * Détermine si on doit afficher la question 3 sur l'interdiction d'accueil.
		 */
		concerneParInterdictionAccueil: (state) => {
			return state.effectif === "inf250"
		},

		/**
		 * Détermine si on doit afficher les questions 4 et 5 sur la baisse du CA en Décembre et Janvier.
		 */
		concerneParBaisseCA: (state) => {
			return state.interdictionAccueil === "non"
		},
	},

	// =======================================================
	// == MUTATIONS
	// =======================================================

	mutations: {

		setSecteur(state, secteur) {
			// Màj
			state.secteur = secteur

			// Reset suivants
			state.effectif = undefined
			state.interdictionAccueil = undefined
			state.baisseCADecembre = undefined
			state.baisseCAJanvier = undefined
			state.baisseCAFevrier = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setEffectif(state, effectif) {
			// Màj
			state.effectif = effectif

			// Reset suivants
			state.interdictionAccueil = undefined
			state.baisseCADecembre = undefined
			state.baisseCAJanvier = undefined
			state.baisseCAFevrier = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setInterdictionAccueil(state, interdictionAccueil) {
			// Màj
			state.interdictionAccueil = interdictionAccueil

			// Reset suivants
			state.baisseCADecembre = undefined
			state.baisseCAJanvier = undefined
			state.baisseCAFevrier = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setBaisseCADecembre(state, baisseCA) {
			// Màj
			state.baisseCADecembre = baisseCA

			// Reset suivants :
			state.baisseCAJanvier = undefined
			state.baisseCAFevrier = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setBaisseCAJanvier(state, baisseCA) {
			// Màj
			state.baisseCAJanvier = baisseCA

			// Reset suivants :
			state.baisseCAFevrier = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setBaisseCAFevrier(state, baisseCA) {
			// Màj
			state.baisseCAFevrier = baisseCA

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setReponseEnvoyee(state) {
			state.reponseEnvoyee = true
		},
	},

	// =======================================================
	// == ACTIONS
	// =======================================================

	actions: {

		async postReponse(context) {

			const formId = context.state.formId
			const reponses = context.getters.reponses
			const eligibilite = context.getters.eligibilite

			const resp = await postReponse(formId, {
				reponses,
				eligibilite,
			})
			console.log("Réponse envoyée", resp)

			context.commit('m3/setReponseEnvoyee')
		},

	},
}
