<template>
	<Question :demo="demo">
		<template v-slot:question>
			Quel est l'effectif de votre entreprise ?
		</template>

		<template v-slot:aide>
			L'effectif pris en compte peut être celui calculé au 31 décembre 2019, au 31 décembre 2020 ou, pour les entreprises créées en 2020, au dernier jour du mois au cours duquel a été réalisée la première embauche.
		</template>

		<template v-slot:input>
			<b-field>
				<b-radio
					v-model="effectif"
					native-value="inferieur"
					:disabled="demo"
				>
					Inférieur à {{seuil}} salariés
				</b-radio>
			</b-field>
			<b-field>
				<b-radio
					v-model="effectif"
					native-value="superieur"
					:disabled="demo"
				>
					Supérieur ou égal à {{seuil}} salariés
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'

export default {
	components: {
		Question,
	},

	name: 'QEffectif',

	props: {
		demo: Boolean,
	},

	computed: {
		seuil() {
			switch (this.$store.state.m2.secteur) {
				case "S1":
				case "S1bis":
					return 250

				case "S2":
					return 50

				default:
					return "<ERREUR>"
			}
		},

		effectif: {
			get () {
				return this.$store.state.m2.effectif
			},
			set (effectif) {
				this.$store.commit('m2/setEffectif', effectif)
			}
		},
	},
}
</script>

<style scoped>

</style>
