import { render, staticRenderFns } from "./2_Effectif.vue?vue&type=template&id=80ac4736&scoped=true&"
import script from "./2_Effectif.vue?vue&type=script&lang=js&"
export * from "./2_Effectif.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ac4736",
  null
  
)

export default component.exports