<template>
	<div class="box">

		<!-- === Mois aide + exo === -->
		<template v-if="eligibilite.aidePlusExo.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Aide au paiement de 20 % et exonération de cotisations patronales" type="is-success" :closable="false">
					Vous êtes éligible à l'aide au paiement de 20 % et à l'exonération de cotisations patronales sur
					<ul>
						<li v-for="mois in eligibilite.aidePlusExo" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>
				</b-message>
			</div>
		</template>

		<!-- === Mois aide 20% only === -->
		<template v-if="eligibilite.aide20.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Aide au paiement de 20 % uniquement" type="is-success" :closable="false">
					Vous êtes éligible à l'aide au paiement de 20 % sur
					<ul>
						<li v-for="mois in eligibilite.aide20" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>
				</b-message>
			</div>
		</template>

		<!-- === Mois aide 15% only === -->
		<template v-if="eligibilite.aide15.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Aide au paiement dégressive de 15 %" type="is-success" :closable="false">
					Vous êtes éligible à l'aide au paiement dégressive de 15 % sur
					<ul>
						<li v-for="mois in eligibilite.aide15" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>
				</b-message>
			</div>
		</template>

		<!-- === Mois sans rien === -->
		<template v-if="eligibilite.rien.length > 0">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Aucune aide ou exonération" type="is-warning" :closable="false">
					Vous n'ouvrez droit à aucune aide ou exonération de cotisations pour
					<ul>
						<li v-for="mois in eligibilite.rien" :key="mois">
							<strong v-html="mois"></strong>
						</li>
					</ul>
				</b-message>
			</div>
		</template>

		<!-- === Avertissement aides non cumulables === -->
		<template v-if="eligibilite.rien.length < 3">
			<div class="eligibilite-subtitle m-3 has-text-centered">
				<b-message title="Attention" :closable="false">
					<p>Ces aides ne sont pas cumulables avec l'aide renforcée au titre des "coûts fixes".</p>
					<p>En savoir plus sur cette aide : <a href="https://www.impots.gouv.fr/fv-en-attente-fds-cts-fixes" target="_blank">https://www.impots.gouv.fr/fv-en-attente-fds-cts-fixes</a></p>
				</b-message>
			</div>
		</template>

		<!-- === RÉCAPITULATIF === -->
		<section id="recap" class="m-3 pt-5">
			<h4 class="title">Récapitulatif de vos réponses</h4>
			<QSecteur demo />
			<QEffectif demo v-if="concerneParEffectif" />
			<QInterdictionAccueil demo v-if="concerneParInterdictionAccueil" />
			<QBaisseCADecembre demo v-if="concerneParBaisseCA" />
			<QBaisseCAJanvier demo v-if="concerneParBaisseCA" />
			<QBaisseCAFevrier demo v-if="concerneParBaisseCA" />
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import QSecteur from '@/components/MesuresCovid3/1_Secteur.vue'
import QEffectif from '@/components/MesuresCovid3/2_Effectif.vue'
import QInterdictionAccueil from '@/components/MesuresCovid3/3_InterdictionAccueil.vue'
import QBaisseCADecembre from '@/components/MesuresCovid3/4_BaisseCADecembre.vue'
import QBaisseCAJanvier from '@/components/MesuresCovid3/5_BaisseCAJanvier.vue'
import QBaisseCAFevrier from '@/components/MesuresCovid3/6_BaisseCAFevrier.vue'

export default {
	components: {
		QSecteur,
		QEffectif,
		QInterdictionAccueil,
		QBaisseCADecembre,
		QBaisseCAJanvier,
		QBaisseCAFevrier,
	},

	name: 'Eligibilite-m3',

	computed: {
		...mapGetters('m3', [
			'concerneParEffectif',
			'concerneParInterdictionAccueil',
			'concerneParBaisseCA',
			'eligibilite',
		])
	},
}
</script>

<style scoped lang="scss">
@media print {
	.eligibilite {
		background-color: transparent !important;

		* {
			color: black !important;
			font-size: 15pt !important;
		}

		.hero-body {
			padding-top: 3pt;
			padding-bottom: 3pt;
		}
	}

	.eligibilite-subtitle {
		padding-top: 3pt;
		padding-bottom: 3pt;

		* {
			font-size: 13pt !important;
		}
	}

	.eligibilite-message p {
		font-size: 11pt !important;
	}

	#recap h4 {
		font-size: 13pt;
	}
}
</style>
