import Vue from 'vue'
import Buefy from "buefy"
import App from './App.vue'
import store from './store'
import './assets/buefy-theme.scss'
import './assets/styles.scss'
import router from './router'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(Buefy)

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')
