<template>
	<Question :demo="demo">
		<template v-slot:question>
			Avez-vous subi une interdiction d'accueil ou une baisse de chiffre d'affaires sur le mois de décembre 2021 ?
		</template>

		<template v-slot:aide>
			Le chiffre d'affaires de décembre 2021 est à comparer :
			<ul>
				<li>soit au chiffre d'affaires de décembre 2019 ou décembre 2020,</li>
				<li>soit au chiffre d'affaires mensuel moyen de l'année 2019 ou de l'année 2020,</li>
				<li>soit, pour les entreprises créées en 2021, au montant mensuel moyen du chiffre d'affaires réalisé entre la date de création de l'entreprise et le 31 août 2021.</li>
			</ul>
		</template>

		<template v-slot:input>
			<b-field>
				<b-radio
					v-model="baisseCA"
					native-value="sup65"
					:disabled="demo"
				>
					Oui, j'ai subi une interdiction d'accueil ou une perte de plus de 65 %
				</b-radio>
			</b-field>
			<b-field>
				<b-radio
					v-model="baisseCA"
					native-value="30_65"
					:disabled="demo"
				>
					Oui, j'ai subi une perte comprise entre 30 % et 65 %
				</b-radio>
			</b-field>
			<b-field>
				<b-radio
					v-model="baisseCA"
					native-value="inf30"
					:disabled="demo"
				>
					J'ai subi une perte de moins de 30 % ou pas de perte du tout
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'

export default {
	components: {
		Question,
	},
	name: 'QBaisseCADecembre',

	props: {
		demo: Boolean,
	},

	computed: {
		baisseCA: {
			get() {
				return this.$store.state.m3.baisseCADecembre
			},
			set(value) {
				this.$store.commit('m3/setBaisseCADecembre', value)
			}
		},
	},
}
</script>

<style scoped>

</style>
