<template>
	<div class="container">
		<b-steps
			id="stepper"
			v-model="activeStep"
			:animated="true"
			:rounded="true"
			:has-navigation="true"
			mobile-mode="minimalist"
		>
			<!-- 0 -->
			<b-step-item step="i" label="Présentation">
				<Presentation />
			</b-step-item>

			<!-- 1 -->
			<b-step-item step="1" label="Secteur d'activité">
				<QSecteur />
			</b-step-item>

			<!-- 2 -->
			<b-step-item step="2" label="Effectif" :visible="concerneParEffectif">
				<QEffectif />
			</b-step-item>

			<!-- 3 -->
			<b-step-item step="3" label="Interdiction d'accueil" :visible="concerneParInterdictionAccueil">
				<QInterdictionAccueil />
			</b-step-item>

			<!-- 4 -->
			<b-step-item step="4" label="CA Décembre" :visible="concerneParBaisseCA">
				<QBaisseCADecembre />
			</b-step-item>

			<!-- 5 -->
			<b-step-item step="5" label="CA Janvier" :visible="concerneParBaisseCA">
				<QBaisseCAJanvier />
			</b-step-item>

			<!-- 6 -->
			<b-step-item step="6" label="CA Février" :visible="concerneParBaisseCA">
				<QBaisseCAFevrier />
			</b-step-item>

			<!-- 7 -->
			<b-step-item step="✓" label="Éligibilité">
				<Eligibilite />
			</b-step-item>

			<!-- Précédent / Suivant / Terminer -->
			<template #navigation="{ previous, next }">
				<div id="nav-stepper" class="container has-text-centered">
					<b-button
						class="mx-1" type="is-link is-light"
						:disabled="previous.disabled"
						@click.prevent="previous.action"
					>
						Précédent
					</b-button>
					<b-button
						class="mx-1" type="is-link"
						v-if="!showTermine && activeStep !== LAST_STEP"
						:disabled="next.disabled || mostAdvancedFilledStep < activeStep"
						@click.prevent="next.action"
					>
						Suivant
					</b-button>
					<b-button
						class="mx-1" type="is-success"
						v-if="showTermine && activeStep !== LAST_STEP"
						@click.prevent="terminer"
					>
						Terminer
					</b-button>
				</div>
			</template>
		</b-steps>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Presentation from '@/components/MesuresCovid3/0_Presentation.vue'
import QSecteur from '@/components/MesuresCovid3/1_Secteur.vue'
import QEffectif from '@/components/MesuresCovid3/2_Effectif.vue'
import QInterdictionAccueil from '@/components/MesuresCovid3/3_InterdictionAccueil.vue'
import QBaisseCADecembre from '@/components/MesuresCovid3/4_BaisseCADecembre.vue'
import QBaisseCAJanvier from '@/components/MesuresCovid3/5_BaisseCAJanvier.vue'
import QBaisseCAFevrier from '@/components/MesuresCovid3/6_BaisseCAFevrier.vue'
import Eligibilite from '@/components/MesuresCovid3/7_Eligibilite.vue'

export default {
	components: {
		Presentation,
		QSecteur,
		QEffectif,
		QInterdictionAccueil,
		QBaisseCADecembre,
		QBaisseCAJanvier,
		QBaisseCAFevrier,
		Eligibilite,
	},

	name: 'MesuresCovid3',

	data: () => ({
		LAST_STEP: 7,

		/** Indice de la question affichée */
		activeStep: 0,
	}),

	computed: {
		/**
		 * Indice de la dernière question répondue.
		 */
		mostAdvancedFilledStep() {
			if (this.$store.state.m3.baisseCAFevrier) { return 6 }
			if (this.$store.state.m3.baisseCAJanvier) { return 5 }
			if (this.$store.state.m3.baisseCADecembre) { return 4 }
			if (this.$store.state.m3.interdictionAccueil) { return 3 }
			if (this.$store.state.m3.effectif) { return 2 }
			if (this.$store.state.m3.secteur) { return 1 }
			return 0
		},

		/**
		 * S'il faut afficher le bouton "Terminer" au lieu de "Suivant".
		 */
		showTermine() {

			let lastStep = 1;
			if (this.concerneParEffectif) { lastStep = 2; }
			if (this.concerneParInterdictionAccueil) { lastStep = 3; }
			if (this.concerneParBaisseCA) { lastStep = 6; } // (sic)

			return (
				// On est au dernier step répondu (pour pouvoir faire "Suivant" si on est retourné en arrière) :
				this.mostAdvancedFilledStep === this.activeStep &&

				// On vient de répondre à la dernière question :
				this.mostAdvancedFilledStep === lastStep
			)
		},

		...mapGetters('m3', [
			'concerneParEffectif',
			'concerneParInterdictionAccueil',
			'concerneParBaisseCA',
		])
	},

	methods: {
		terminer() {
			this.activeStep = this.LAST_STEP

			if (!this.$store.state.m3.reponseEnvoyee) {
				this.$store.dispatch("m3/postReponse")
			}
		}
	}
}
</script>

<style>
@media print {
	#stepper > nav.steps {
		display: none;
	}

	#nav-stepper {
		display: none;
	}
}
</style>
