import Vue from 'vue'
import Vuex from 'vuex'
import { mesures2store } from './mesures2.store'
import { mesures2sortieStore } from './mesures2sortie.store'
import { mesures3Store } from './mesures3.store'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		m2: mesures2store,
		m2s: mesures2sortieStore,
		m3: mesures3Store,
	}
})

// =======================================================
// == COMMONS
// =======================================================

export const classes = {
	normal: {
		sectionQuestion: ['section'],
		question: ['title', 'is-4'],
		sectionInputs: ['section'],
	},
	demo: {
		sectionQuestion: [],
		question: ['title', 'is-6', 'has-text-grey'],
		sectionInputs: ['mt-5'],
	},
}
