<template>
	<Question :demo="demo">
		<template v-slot:question>
			Avez-vous subi une baisse de chiffre d'affaires d'au moins 50% au cours d'un des mois suivants ?
		</template>

		<template v-slot:aide>
			Pour en savoir sur les critères de chiffre d'affaires, <a href="https://ghs.fr/IMG/pdf/COVID2-BaisseCA.pdf" target="_blank">cliquez ici</a>.
		</template>

		<template v-slot:input>
			<b-field v-for="choix in choixRestants" :key="choix.value">
				<b-checkbox
					v-model="baisseCA"
					:native-value="choix.value"
					:disabled="demo"
					@input="setMois($event, false)"
				>
					<span v-html="choix.label"></span>
				</b-checkbox>
			</b-field>
			<b-field>
				<b-checkbox
					v-model="baisseCA"
					native-value="aucun"
					:disabled="demo"
					@input="setMois($event, true)"
				>
					Je n'ai pas subi de baisse de chiffre d'affaires d'au moins 50%
				</b-checkbox>
			</b-field>
		</template>
	</Question>
</template>

<script>
import { MoisListe } from '@/store/mesures2.store'
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},
	name: 'QBaisseCA',

	props: {
		demo: Boolean,
	},

	computed: {
		baisseCA: {
			get() {
				return this.$store.state.m2.baisseCA || []
			},
			set() { /* Ne rien faire car methods.setMois s'en charge */ }
		},

		/**
		 * Pour les secteurs S1 et S1bis, on ajoute le mois d'octobre ssi couvreFeuOctobre (géré par le store Vuex).
		 */
		choixRestants() {

			const stateMois = this.$store.getters['m2/mois'];
			const choix = [];

			MoisListe.forEach(mois => {
				if (stateMois[mois].baisseCA) {
					choix.push({
						label: stateMois[mois].label,
						value: mois,
					})
				}
			});

			return choix
		},
	},

	methods: {

		setMois(event, isAucun) {
			let choix;

			if (event.indexOf('aucun') > -1) {
				if (isAucun) {
					// On a coché "aucun" : ne garder que lui
					choix = ["aucun"]
				}
				else {
					// On a coché autre chose, retirer "aucun" de la liste
					choix = event.filter(ch => ch !== 'aucun')
				}
			}
			else {
				choix = event
			}

			if (choix.length === 0) {
				choix = undefined
			}

			this.$store.commit('m2/setBaisseCA', choix)
		}
	}
}
</script>

<style scoped>

</style>
