const axios = require('axios').default
import { Config } from '../config';

const API_ENDPOINT = Config.API_ENDPOINT

/**
 * @param {String} formId ID du formulaire
 * @param {Object} content Réponses et résultat
 */
export function postReponse(formId, content) {
	console.log("Envoi de la réponse...", formId, content);
	return axios.post(`${API_ENDPOINT}/reponses`, {
		form: formId,
		content,
	})
}
