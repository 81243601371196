<template>
	<Question :demo="demo">
		<template v-slot:question>
			Vous êtes une discothèque ou une salle de danse ayant subi une interdiction d'accueil du public du 10 décembre 2021 au 16 février 2022 ?
		</template>

		<template v-slot:input>
			<b-field>
				<b-radio
					v-model="interdictionAccueil"
					native-value="oui"
					:disabled="demo"
				>
					Oui
				</b-radio>
			</b-field>
			<b-field>
				<b-radio
					v-model="interdictionAccueil"
					native-value="non"
					:disabled="demo"
				>
					Non
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},
	name: 'QInterdictionAccueilPublic',

	props: {
		demo: Boolean,
	},

	computed: {
		interdictionAccueil: {
			get () {
				return this.$store.state.m3.interdictionAccueil
			},
			set (interdictionAccueil) {
				this.$store.commit('m3/setInterdictionAccueil', interdictionAccueil)
			}
		},
	},
}
</script>

<style scoped>

</style>
