<template>
	<div class="container">
		<p>Partagez cette page sur</p>

		<PartageBouton v-for="reseau in reseaux" :key="reseau"
			:reseau="reseau"
			:url="url"
			titreCourt="Mesures exceptionnelles COVID-19 - 2ème période"
			description="Découvrez si vous êtes éligible en 5 questions simples"
			titreSolo="Êtes-vous éligibles aux mesures exceptionnelles COVID-19 - 2ème période ?"
		/>

	</div>
</template>

<script>
import PartageBouton from './PartageBouton.vue'
export default {
	components: {
		PartageBouton,
	},

	name: 'Partage',

	data: () => ({
		reseaux: [
			'facebook', 'twitter', 'linkedin', 'telegram', 'email',
		],
		url: window.location.href,
	}),
}
</script>

<style scoped>
partage-bouton {
	display: none;
}
</style>
