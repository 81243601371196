<template>
	<footer class="footer">
		<div class="content has-text-centered">
			<div class="columns">

				<!-- spacer -->
				<div class="column no-print"></div>

				<!-- GAUCHE -->
				<div class="column">
					<p>Reposez-vous sur</p>
					<a href="https://www.ghs.fr/" target="_blank">
						<img class="logo" alt="Logo GHS" src="../assets/GHS.svg" />
					</a>
				</div>

				<!-- DROITE -->
				<div class="column no-print">
					<Partage />
				</div>

				<!-- spacer -->
				<div class="column no-print"></div>
			</div>
		</div>
	</footer>
</template>

<script>
import Partage from "./Partage.vue";
export default {
	components: {
		Partage,
	},

	name: 'Footer',
}
</script>

<style scoped lang="scss">
footer {
	margin-top: 100px;

	.logo {
		max-height: 80px;
	}

	@media print {
		margin-top: 0;
		background-color: transparent !important;

		.no-print {
			display: none;
		}
	}
}
</style>
