<template>
	<div class="box">
		<!-- === ÉLIGIBLE === -->
		<template v-if="moisValides.length > 0">
			<section class="eligibilite eligibilite-ok hero is-success">
				<div class="hero-body has-text-centered">
					<p class="title is-3">
						Vous êtes éligible aux mesures exceptionnelles COVID-19 - 2ème période
					</p>
				</div>
			</section>

			<div class="eligibilite-subtitle section has-text-centered my-3">
				<p class="subtitle is-4">
					au titre des paies {{ moisValides.length > 1 ? "des" : "du" }} mois de :
					<ul>
						<li v-for="mois in moisValides" :key="mois.label">
							<strong v-html="mois.moisEligible"></strong>
						</li>
					</ul>
				</p>

				<!-- EXPLICATIONS -->
				<div class="eligibilite-message message">
					<p class="message-body">
						L'exonération est calculée sur les rémunérations dues au titre de la période d'emploi M-1 lorsque les conditions sont réunies sur le mois M.
					</p>
				</div>
			</div>
		</template>

		<!-- === NON ÉLIGIBLE === -->
		<section class="eligibilite eligibilite-ko hero is-danger" v-if="moisValides.length < 1">
			<div class="hero-body has-text-centered">
				<p class="title is-3">
					Vous n'êtes pas éligible aux mesures exceptionnelles COVID-19 - 2ème période
				</p>
			</div>
		</section>

		<!-- === RÉCAPITULATIF === -->
		<section id="recap" class="section">
			<h4 class="title">Récapitulatif de vos réponses</h4>
			<QSecteur demo />
			<QEffectif demo v-if="concerneParEffectif" />
			<QCouvreFeu demo v-if="concerneParCouvreFeuOctobre" />
			<QInterdictionAccueilPublic demo v-if="concerneParInterdictionAccueil" />
			<QBaisseCA demo v-if="concerneParBaisseCA" />
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import QSecteur from './1_Secteur.vue'
import QEffectif from './2_Effectif.vue'
import QCouvreFeu from './3_CouvreFeu.vue'
import QInterdictionAccueilPublic from './4_InterdictionAccueilPublic.vue'
import QBaisseCA from './5_BaisseCA.vue'
export default {
	components: {
		QSecteur,
		QEffectif,
		QCouvreFeu,
		QInterdictionAccueilPublic,
		QBaisseCA,
	},

	name: 'Eligibilite',

	computed: {
		...mapGetters('m2', [
			'moisValides',
			'concerneParEffectif',
			'concerneParCouvreFeuOctobre',
			'concerneParInterdictionAccueil',
			'concerneParBaisseCA',
		])
	},
}
</script>

<style scoped lang="scss">
@media print {
	.eligibilite {
		background-color: transparent !important;

		* {
			color: black !important;
			font-size: 15pt !important;
		}

		.hero-body {
			padding-top: 3pt;
			padding-bottom: 3pt;
		}
	}

	.eligibilite-subtitle {
		padding-top: 3pt;
		padding-bottom: 3pt;

		* {
			font-size: 13pt !important;
		}
	}

	.eligibilite-message p {
		font-size: 11pt !important;
	}

	#recap h4 {
		font-size: 13pt;
	}
}
</style>
