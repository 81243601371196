<template>
	<Question :demo="demo">
		<template v-slot:question>
			Avez-vous bénéficié des aides et exonérations sur les mois de février ou mars ou avril 2021 ?
		</template>

		<template v-slot:aide>
			<p>
				Un seul des 3 mois suffit à valider la réponse.
			</p>
		</template>

		<template v-slot:input>
			<b-field v-for="choi in choix" :key="choi.value">
				<b-radio
					v-model="aidesFMA21"
					:native-value="choi.value"
					:disabled="demo"
				>
					<span v-html="choi.label"></span>
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},

	name: 'QAidesFMA21-m2s',

	props: {
		demo: Boolean,
	},

	data: () => ({
		choix: [
			{ value: "oui", label: "Oui" },
			{ value: "non", label: "Non" },
		],
	}),

	computed: {
		aidesFMA21: {
			get () {
				return this.$store.state.m2s.aidesFMA21
			},
			set (aidesFMA21) {
				this.$store.commit('m2s/setAidesFMA21', aidesFMA21)
			}
		},
	},
}
</script>

<style scoped>

</style>
