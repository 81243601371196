import { postReponse } from '../api'

/**
 * Store du formulaire sur la sortie dégressive.
 * @see https://github.com/GHSFR/adapdoc-front/issues/3
 */
export const mesures2sortieStore = {

	namespaced: true,
	strict: process.env.NODE_ENV !== 'production',

	// =======================================================
	// == STATE
	// =======================================================

	state: {
		// === Questions ===
		/**
		 * @type {'juin' | 'juinJuillet' | 'non'}
		 */
		interdictionAccueil: undefined,
		/**
		 * @type {'S1_ou_inf250' | 'NonS1_ou_sup250'}
		 */
		secteurEffectif: undefined,
		/**
		 * @type {'oui' | 'non'}
		 */
		jauge: undefined,
		/**
		 * @type {'oui' | 'non'}
		 */
		aidesFMA21: undefined,

		// === Stats ===
		formId: "mesures-covid-2-sortie-v1",
		reponseEnvoyee: false,
	},

	// =======================================================
	// == GETTERS
	// =======================================================

	getters: {

		/**
		 * Les réponses données, pour être envoyées à l'API pour les stats.
		 */
		reponses: (state) => {
			return {
				interdictionAccueil: state.interdictionAccueil,
				secteurEffectif: state.secteurEffectif,
				jauge: state.jauge,
				aidesFMA21: state.aidesFMA21,
			}
		},

		/**
		 * Calcule les mois éligibles à exonération ou à la sortie dégressive en fonction des réponses données.
		 */
		eligibilite: (state) => {
			const mois = {
				complets: [],
				degressifs: [],
				rien: [],
			}

			// Rien si...
			if (
				// 1. Pas d'interdiction et autre secteur :
				(
					state.interdictionAccueil === "non" &&
					state.secteurEffectif === "NonS1_ou_sup250"
				) ||
				// 2. OU pas de jauge et pas d'aides FMA21 :
				(
					state.jauge === "non" &&
					state.aidesFMA21 === "non"
				)
			) {
				mois.rien = ["Mai 2021", "Juin 2021", "Juillet 2021"]
				return mois
			}

			// Mai :
			// - dégressif si Jauge == "non" (implique "pas d'interdiction")
			// - complet sinon
			if (state.jauge === "non") {
				mois.degressifs.push("Mai 2021")
			}
			else {
				mois.complets.push("Mai 2021")
			}

			// Juin :
			// - complet si interdiction juinJuillet
			// - dégressif si secteur S1 et aides FMA21
			if (state.interdictionAccueil === "juinJuillet") {
				mois.complets.push("Juin 2021")
			}
			else if (
				state.secteurEffectif === "S1_ou_inf250" &&
				state.aidesFMA21 === "oui"
			) {
				mois.degressifs.push("Juin 2021")
			}
			else {
				mois.rien.push("Juin 2021")
			}

			// Juillet :
			// - dégressif si secteur S1 et aides FMA21
			if (
				state.secteurEffectif === "S1_ou_inf250" &&
				state.aidesFMA21 === "oui"
			) {
				mois.degressifs.push("Juillet 2021")
			}
			else {
				mois.rien.push("Juillet 2021")
			}

			return mois
		},

		/**
		 * Détermine si la question 3 sur la Jauge doit être affichée, en fonction des réponses aux questions précédentes.
		 */
		concerneParJauge: (state) => {
			return (
				state.interdictionAccueil === 'non' &&
				state.secteurEffectif === 'S1_ou_inf250'
			)
		},

		/**
		 * Détermine si la question 4 sur les aides de Février/Mars/Avril 2021 doit être affichée, en fonction des réponses aux questions précédentes.
		 */
		concerneParAidesFMA21: (state) => {
			return (
				state.secteurEffectif === 'S1_ou_inf250'
			)
		},

	},

	// =======================================================
	// == MUTATIONS
	// =======================================================

	mutations: {

		setInterdictionAccueil(state, interdictionAccueil) {
			// Màj
			state.interdictionAccueil = interdictionAccueil

			// Reset suivants
			state.secteurEffectif = undefined
			state.jauge = undefined
			state.aidesFMA21 = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setSecteurEffectif(state, secteurEffectif) {
			// Màj
			state.secteurEffectif = secteurEffectif

			// Reset suivants
			state.jauge = undefined
			state.aidesFMA21 = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setJauge(state, jauge) {
			// Màj
			state.jauge = jauge

			// Reset suivants :
			state.aidesFMA21 = undefined

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setAidesFMA21(state, aidesFMA21) {
			// Màj
			state.aidesFMA21 = aidesFMA21

			// Reset reponseEnvoyee
			state.reponseEnvoyee = false
		},

		setReponseEnvoyee(state) {
			state.reponseEnvoyee = true
		},
	},

	// =======================================================
	// == ACTIONS
	// =======================================================

	actions: {

		async postReponse(context) {

			const formId = context.state.formId
			const reponses = context.getters.reponses
			const eligibilite = context.getters.eligibilite

			const resp = await postReponse(formId, {
				reponses,
				eligibilite,
			})
			console.log("Réponse envoyée", resp)

			context.commit('m2/setReponseEnvoyee')
		},

	},
}
