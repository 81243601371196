<template>
	<Question :demo="demo">
		<template v-slot:question>
			<!-- S2 -->
			<template v-if="versionS2">
				Avez-vous subi une interdiction d'accueil du public "affectant de manière prépondérante votre activité" au cours des mois suivants ?
			</template>

			<!-- S1(bis) -->
			<template v-if="!versionS2">
				Avez-vous subi une interdiction d'accueil du public au cours des mois suivants ?
			</template>
		</template>

		<template v-slot:aide>
			<p>
				Satisfont le critère d'interdiction d'accueil du public les activités qui ont été interrompues en application du <a href="https://www.service-public.fr/professionnels-entreprises/vosdroits/N31782" target="_blank">décret n°2020-1310 du 29/10/2020</a> ou d'une décision locale.
			</p>
			<p>
				En revanche, les cas où l'activité a été interrompue de manière volontaire par l'employeur n'ouvrent pas droit aux dispositifs.
			</p>
		</template>

		<template v-slot:input>
			<b-field v-for="choix in choix" :key="choix.value">
				<b-checkbox
					v-model="interdictionAccueil"
					:native-value="choix.value"
					:disabled="demo"
					@input="setMois($event, false)"
				>
					<span v-html="choix.label"></span>
				</b-checkbox>
			</b-field>
			<b-field>
				<b-checkbox
					v-model="interdictionAccueil"
					native-value="aucun"
					:disabled="demo"
					@input="setMois($event, true)"
				>
					Je n'ai pas subi d'interdiction d'accueil du public
				</b-checkbox>
			</b-field>
		</template>
	</Question>
</template>

<script>
import { MoisListe } from '@/store/mesures2.store'
import Question from '@/components/Question.template.vue'
export default {
	components: {
		Question,
	},
	name: 'QInterdictionAccueilPublic',

	props: {
		demo: Boolean,
	},

	computed: {
		interdictionAccueil: {
			get() {
				return this.$store.state.m2.interdictionAccueil || []
			},
			set() { /* Ne rien faire car methods.setMois s'en charge */ }
		},

		/**
		 * La question est formulée différemment pour le secteur S2.
		 */
		versionS2() { return this.$store.state.m2.secteur === "S2" },

		/**
		 * Pour les secteurs S1 et S1bis, on ajoute le mois d'octobre ssi couvreFeuOctobre (géré par le store Vuex).
		 */
		choix() {

			const stateMois = this.$store.getters['m2/mois'];
			const choix = [];

			MoisListe.forEach(mois => {
				if (stateMois[mois].interdictionAccueil) {
					choix.push({
						label: stateMois[mois].label,
						value: mois,
					})
				}
			});

			return choix
		},
	},

	methods: {
		setMois(event, isAucun) {
			let choix;

			if (event.indexOf('aucun') > -1) {
				if (isAucun) {
					// On a coché "aucun" : ne garder que lui
					choix = ["aucun"]
				}
				else {
					// On a coché autre chose, retirer "aucun" de la liste
					choix = event.filter(ch => ch !== 'aucun')
				}
			}
			else {
				choix = event
			}

			if (choix.length === 0) {
				choix = undefined
			}

			this.$store.commit('m2/setInterdictionAccueil', choix)
		}
	}
}
</script>

<style scoped>

</style>
