<template>
	<Question :demo="demo">
		<template v-slot:question>
			À quel secteur d'activité appartenez-vous ?
		</template>

		<template v-slot:aide>
			Si vous ne savez pas, vous pouvez consulter <a href="https://ghs.fr/IMG/pdf/COVID2-ListeSecteurs.pdf" target="_blank">la liste des secteurs</a>.
		</template>

		<template v-slot:input>
			<b-field v-for="sect in secteurs" :key="sect.value">
				<b-radio
					v-model="secteur"
					:native-value="sect.value"
					:disabled="demo"
				>
					<span v-html="sect.label"></span>
				</b-radio>
			</b-field>
		</template>
	</Question>
</template>

<script>
import Question from '@/components/Question.template.vue'

export default {
	components: {
		Question,
	},

	name: 'QSecteur',

	props: {
		demo: Boolean,
	},

	data: () => ({
		secteurs: [
			{ value: "S1_ou_bis", label: "Secteur <strong>S1</strong> ou <strong>S1bis</strong>" },
			{ value: "aucun", label: "Je n'appartiens à aucun de ces deux secteurs" },
		],
	}),

	computed: {
		secteur: {
			get () {
				return this.$store.state.m3.secteur
			},
			set (secteur) {
				this.$store.commit('m3/setSecteur', secteur)
			}
		},
	},
}
</script>

<style scoped>

</style>
